var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-h-full tw-w-full tw-relative",attrs:{"data-flank":"side-panel-navigator"}},_vm._l((_vm.getActivePath()),function(node,index){return _c('div',{key:index,staticClass:"tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-bottom-0",style:({ zIndex: index * 10 })},[(index === 0)?[_vm._t(("" + (node.id) + (node.behavior ? '$scrolls' : '')),null,{"dataFlank":("navigator-slot-" + (node.id))})]:_vm._e(),(index > 0)?_c('div',{staticClass:"tw-bg-gray-200 tw-opacity-30 tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-bottom-0",on:{"click":function($event){return _vm.pop()}}}):_vm._e(),_c('transition',{attrs:{"name":"slide"}},[(index > 0)?_c('div',{staticClass:"tw-bg-white tw-absolute tw-right-0 tw-top-0 tw-bottom-0 tw-left-0 tw-shadow-lg",class:index === 1
            ? 'md:tw-left-4'
            : index === 2
            ? 'md:tw-left-8'
            : index === 3
            ? 'md:tw-left-12'
            : index === 4
            ? 'md:tw-left-16'
            : 'md:tw-left-20',style:({ zIndex: index * 10 + 4 })},[_c('div',{staticClass:"tw-w-full tw-h-full tw-p-0",class:{ 'tw-overflow-y-auto': _vm.scrolls || node.behavior },attrs:{"id":(_vm.navigatorHtmlId + "-" + index)}},[_vm._t(("" + (node.id) + (node.behavior ? '$scrolls' : '')),null,{"dataFlank":("navigator-slot-" + (node.id))}),_c('a',{staticClass:"tw-absolute tw-left-4 md:tw-left-5 tw-top-3.5 md:tw-top-4 tw-flex tw-flex-row tw-items-center",style:({ zIndex: index * 10 + 6 }),on:{"click":function($event){return _vm.pop()}}},[_c('span',{staticClass:"md:tw-hidden"},[_c('b-icon',{attrs:{"icon":"arrow-left","size":"is-small"}})],1),_c('span',{staticClass:"tw-hidden md:tw-block"},[_c('b-icon',{attrs:{"icon":"arrow-left"}})],1)])],2)]):_vm._e()])],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }